const SCHOOL_ADMIN = 'User.Admin';
const USER_ROLE_USER = 'User.User';
const USER_ROLE_FLEET_ADMIN = 'fleet.manager';
const USER_ROLE_FLEET_MECHANIC = 'fleet.mechanic';
const USER_ROLE_TRANSPORTATION_MANAGER = 'transportation.manager';
const USER_ROLE_TRANSPORTATION_ADMIN = 'transportation.admin';
const USER_ROLE_TRANSPORTATION_ROUTER = 'transportation.router';
const SUPER_ADMIN = 'super.admin';
const PROFILE_REVIEW_ADMIN = 'profile.review.admin';
const USER_ROLE_COUNSELOR = 'school.counselor';

const ROLES = {
  SCHOOL_ADMIN,
  USER_ROLE_USER,
  USER_ROLE_FLEET_ADMIN,
  USER_ROLE_FLEET_MECHANIC,
  USER_ROLE_TRANSPORTATION_MANAGER,
  USER_ROLE_TRANSPORTATION_ADMIN,
  USER_ROLE_TRANSPORTATION_ROUTER,
  SUPER_ADMIN,
  PROFILE_REVIEW_ADMIN,
};

const STUDENT_TABLE_HEAD = [
  { id: 'lastname', label: 'Name', width: 0.3 },
  { id: 'state_studentnumber', label: 'ASN', width: 0.3 },
  { id: 'grade_level', label: 'Grade', width: 0.2 },
  { id: 'visitLog', label: 'Last Visit', role: USER_ROLE_COUNSELOR, width: 0.2 },
];

const PROFILE_REVIEW_TABLE_HEAD = [
  { id: 'lastname', label: 'Name', width: 250 },
  { id: 'state_studentnumber', label: 'ASN' },
  { id: 'studentProfileRequest.status', label: 'Status' },
  { id: 'grade_level', label: 'Grade' },
  { id: '' },
];

const ENROLLMENT_TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 250 },
  { id: 'grade', label: 'Grade', width: 150 },
  { id: 'school', label: 'School', width: 300 },
  { id: 'status', label: 'Status' },
];

const SCHOOLS = [
  {
    schoolName: 'Blue Hills Community School',
    schoolNumber: 1713,
    schoolAbbreviation: 'BHCS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12],
    community: 'Blue Hills',
  },
  {
    schoolName: 'Buffalo Head Prairie School',
    schoolNumber: 1701,
    schoolAbbreviation: 'BHPS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    community: 'Buffalo Head Prairie',
  },
  { schoolName: 'De Oabeit Collegiate School', schoolNumber: 2633, schoolAbbreviation: 'DOCS' },
  { schoolName: 'FVSD Summer', schoolNumber: 17159, schoolAbbreviation: 'FVSDS' },
  {
    schoolName: 'Florence MacDougall Community School',
    schoolNumber: 1709,
    schoolAbbreviation: 'FMCS',
    grades: [0, 1, 2, 3],
    community: 'High Level',
  },
  {
    schoolName: 'Fort Vermilion Public School',
    schoolNumber: 1702,
    schoolAbbreviation: 'FVPS',
    grades: [7, 8, 9, 10, 11, 12],
    community: 'Fort Vermilion',
  },
  {
    schoolName: 'Gramson Centre Collegiate School',
    schoolNumber: 2634,
    schoolAbbreviation: 'GCCS',
  },
  {
    schoolName: 'High Level Public School',
    schoolNumber: 1703,
    schoolAbbreviation: 'HLPS',
    grades: [7, 8, 9, 10, 11, 12],
    community: 'High Level',
  },
  {
    schoolName: 'Hill Crest Community School',
    schoolNumber: 1712,
    schoolAbbreviation: 'HCCS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    community: 'Blumenort',
  },
  {
    schoolName: 'La Crete Public School',
    schoolNumber: 1704,
    schoolAbbreviation: 'LCPS',
    grades: [7, 8, 9, 10, 12],
    community: 'La Crete',
  },
  { schoolName: 'Northern Outreach Center', schoolNumber: 1742, schoolAbbreviation: 'NOC' },
  {
    schoolName: 'Pathways',
    schoolNumber: 2454,
    schoolAbbreviation: 'PATH',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  },
  { schoolName: 'Pathways Summer', schoolNumber: 24549, schoolAbbreviation: 'PATHS' },
  {
    schoolName: 'Rainbow Lake School',
    schoolNumber: 1705,
    schoolAbbreviation: 'RLKS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    community: 'Rainbow Lake',
  },
  {
    schoolName: 'Ridgeview Central School',
    schoolNumber: 1714,
    schoolAbbreviation: 'RVCS',
    grades: [4, 5, 6],
    community: 'La Crete',
  },
  {
    schoolName: 'Rocky Lane School',
    schoolNumber: 1706,
    schoolAbbreviation: 'RLNS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    community: 'Rocky Lane',
  },
  {
    schoolName: 'Sand Hills Elementary School',
    schoolNumber: 1794,
    schoolAbbreviation: 'SHES',
    grades: [0, 1, 2, 3],
    community: 'La Crete',
  },
  {
    schoolName: 'Sipiwan Centre 15 Collegiate School',
    schoolNumber: 2635,
    schoolAbbreviation: 'SCCS',
  },
  {
    schoolName: 'Spirit of the North Community School',
    schoolNumber: 487,
    schoolAbbreviation: 'SNCS',
    grades: [4, 5, 6],
    community: 'High Level',
  },
  {
    schoolName: 'St. Mary’s Catholic School',
    schoolNumber: 1770,
    schoolAbbreviation: 'SMCS',
    grades: [0, 1, 2, 3, 4, 5, 6],
    community: 'Fort Vermilion',
  },
  {
    schoolName: 'Upper Hay River School',
    schoolNumber: 1791,
    schoolAbbreviation: 'UHRS',
    grades: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    community: 'Hay River',
  },
];

module.exports = {
  ROLES: {
    SCHOOL_ADMIN,
    USER_ROLE_USER,
    USER_ROLE_FLEET_ADMIN,
    USER_ROLE_FLEET_MECHANIC,
    USER_ROLE_TRANSPORTATION_MANAGER,
    USER_ROLE_TRANSPORTATION_ADMIN,
    USER_ROLE_TRANSPORTATION_ROUTER,
    SUPER_ADMIN,
    PROFILE_REVIEW_ADMIN,
    USER_ROLE_COUNSELOR,
  },
  PROFILE_REVIEW_TABLE_HEAD,
  STUDENT_TABLE_HEAD,
  ENROLLMENT_TABLE_HEAD,
  SCHOOLS,
};
